/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */

import Bind from "../../components/Bind"
import { useNavigate } from "react-router-dom"
import { useCallback } from "react"

function Index() {
    const navigate = useNavigate()
    const checkBind = useCallback(async (_user) => {
        window.localStorage.setItem('last_user_id', _user.user_id)
        navigate('/dashboard')
        // window.location.href =
    }, [])

    return <div><Bind onBind={checkBind} /></div>
}

export default Index
