/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */

import { useCallback, useEffect, useRef, useState } from "react"
import { Toast, Button } from 'antd-mobile'
import styles from './index.module.less'
import api from "@/api"
import helper from "../../utils/helper"

function Index() {
    const [list, setList] = useState([])
    const [summary, setSummary] = useState({})
    const [cnIndex, setCnIndex] = useState([])
    const [active, setActive] = useState(null)
    const [savedIndex, setSavedIndex] = useState([])
    const [finish, setFinish] = useState(false)
    const audioRef = useRef(null)
    const lastScrollTopRef = useRef(0)
    const timeoutRef = useRef(0)

    const loadSummary = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getWordsSummary()
        setSummary(res.data)
        tip.close()
    }, [])
    const loadData = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getWords()
        if (!res.data.length) {
            await loadSummary()
            setFinish(true)
        }
        setList(res.data) // .splice(0, 10)
        tip.close()
    }, [loadSummary])

    const handleScroll = useCallback(async () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        // 向上滚动
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(async () => {
            if (scrollTop < lastScrollTopRef.current - 50) {
                const res = await api.getWordsSummary()
                Toast.show({
                    content:
                        <div>已学：{res.data.total_learn_count}&nbsp;&nbsp;&nbsp;剩余：{res.data.total_count - res.data.total_learn_count}</div>,
                    position: 'top',
                })
            }
            lastScrollTopRef.current = scrollTop
        }, 500)
    }, [])


    useEffect(() => {
        document.title = '闪电单词'
        loadData().then()
        // loadSummary().then()
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handlePlay = useCallback((src) => {
        if (audioRef.current && src) {
            audioRef.current.setAttribute('src', src)
        }
    }, [])

    const handleSave = useCallback(async (item) => {
        const _index = list.findIndex((w) => w.word === item.word)
        if (_index !== -1) {
            // handlePlay(item.audio_url)
            setSavedIndex(savedIndex.concat(_index))
            if (item.word) {
                const res = await api.putWords({ word: item.word })
                const _list = helper.deepCopy(list)
                _list[_index] = { ...res.data }
                setList(_list)
            }
        }

    }, [savedIndex, list])

    const handleFinish = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.putFinish()
        if (res.meta.code === 200) {
            await loadSummary()
            setFinish(true)
        }
        tip.close()
    }, [])

    const handleClick = useCallback((item) => {
        handlePlay(item.audio_url)
        setActive(item)
        const _index = list.findIndex((w) => w.word === item.word)
        if (_index !== -1) {
            setCnIndex(cnIndex.concat(_index))
        }
    }, [handlePlay, list, cnIndex])

    return (
        <div className={styles.list}>
            <audio src="" autoPlay ref={audioRef}></audio>
            {list.map((item, idx) => {
                return <div key={idx} className={styles.item} onClick={() => handleClick(item)}>
                    <span className={styles.en}>{item.word}</span>
                    {cnIndex.includes(idx) ? <>
                        <span className={styles.cn}>{item.translation}</span>
                        {
                            savedIndex.includes(idx) ?
                                ''
                                :
                                savedIndex.length && idx < savedIndex[savedIndex.length - 1] ? '' :
                                    <Button className={styles.button} onClick={() => handleSave(item)}>不认识</Button>

                        }
                        <span className={styles.score}>{item.score}</span>
                    </> : ''}

                </div>
            })}
            <div className={styles.footer}>
                {finish ? <div className={styles.summary}>
                    <p>恭喜你，完成本轮学习</p>
                    <p>认识：<strong>{summary.total_learn_count - summary.total_mark_count || 0}</strong></p>
                    <p>不认识：<strong>{summary.total_mark_count || 0}</strong></p>
                </div> : list.length ? <Button block color="primary" onClick={handleFinish}>完成本轮学习</Button> : ''}

            </div>

        </div>
    )

}

export default Index
