/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useRef, useState } from "react"

import api from "../../api"
import Bind from '../../components/Bind'
import Header from './components/Header'
import Summary from './components/Summary'
// import Skills from './components/Skills'
// import Duration from './components/Duration'
// import Progress from './components/Progress'
// import SkillDetail from './components/SkillDetail'
// import Comment from './components/Comment'
// import SpecialProgress from './components/SpecialProgress'
import Today from './components/Today'
import Calendar from './components/Calendar'
import { Toast } from "antd-mobile"
import styles from './index.module.less'
import LevelProgress from "./components/LevelProgress"
import DurationTrend from "./components/DurationTrend"
import helper from '../../utils/helper'


function Index() {
    const [initLoading, setInitLoading] = useState(false)
    const [bind, setBind] = useState(0) // 0其它 1未绑定 2已绑定
    // const [me, setMe] = useState(null)
    const [currentUser, setCurrentUser] = useState(null) // 当前学员
    const [users, setUsers] = useState([]) // 学员列表
    const [detail, setDetail] = useState(null)
    const toast = useRef(null)
    const loadData = useCallback(async (user_id) => {
        let tip = Toast.show({
            icon: 'loading',
            content: 'loading',
        })
        const res = await api.getStatesByUserID(user_id)
        if (res.data) {
            setDetail(res.data)
        }
        tip.close()
    }, [])
    const checkBind = useCallback(async () => {
        setInitLoading(true)
        toast.current = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getBindUsers()
        if (res.data?.length) {
            setUsers(res.data)
            let _lastUserId = helper.getCurrentUserID()
            let _user = res.data[0].user
            if (_lastUserId) {
                let _u = res.data.find((_item) => _item.user_id === +_lastUserId)
                if (_u) {
                    _user = _u.user
                }
            } else {
                helper.setCurrentUserID(_user.id)
            }
            setCurrentUser(_user)
            // 已绑定用户
            setBind(2)
            loadData(_user.id)
        } else {
            // 未绑定用户
            setBind(1)
        }
        // setMe(res.data)
        toast.current.close()
        setInitLoading(false)
    }, [])

    const handleBind = useCallback((user) => {
        checkBind()
    }, [checkBind])

    const handleChangeUser = useCallback(async (_user) => {
        setCurrentUser(_user)
        loadData(_user.id)
    }, [loadData])


    // useEffect(() => {
    //     if (currentUser?.id && lastUserID !== currentUser.id) {
    //         lastUserID = currentUser.id
    //         loadData(currentUser.id)
    //     }
    //     return () => {
    //     }
    // }, [currentUser])
    //

    useEffect(() => {
        checkBind()
        return () => {
        }
    }, [])

    return <div>
        {initLoading ? '' :
            <div>
                {bind === 1 ? <Bind onBind={handleBind} /> : ''}
                {bind === 2 && detail ? <div className={styles.dashboard}>
                    <Header currentUser={currentUser} users={users} onChange={handleChangeUser} />
                    <Summary currentUser={currentUser} question={detail.question_stat}
                             knowledge={detail.knowledge_stat} />
                    <LevelProgress currentUser={currentUser}/>
                    <Today stats={detail.today_task} currentUser={currentUser} />
                    <Calendar currentUser={currentUser} />
                    <DurationTrend currentUser={currentUser} stats={detail.duration_stats} />
                    {/*<ReviewProgress stats={detail.review_tasks} />*/}
                    {/*<Duration currentUser={currentUser} stats={detail.subject_knowledge_stats} />*/}
                    {/*<Skills stats={detail.user_skill_stats} />*/}
                    {/*<Progress stats={detail.user_outlines} />*/}
                    {/*<SkillDetail stats={detail.user_skills} />*/}
                    {/*<SpecialProgress stats={detail.specific_user_outlines} />*/}
                    {/*<Comment comment={detail.comment} />*/}
                </div> : ''}
            </div>
        }
    </div>
}

export default Index
