import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Dashboard from './pages/dashboard'
import User from './pages/user'
import Bind from './pages/bind'
import Redirect from './pages/redirect'
import Words from './pages/words'
import Vocabulary from './pages/vocabulary'
import Destroy from './pages/client/destroy'
import NotFound from './pages/notFound'
import ClientErrorQuestions from './pages/client/questions'
import { MathJaxContext } from "better-react-mathjax"
import './App.less'

document.title = '西瓜学习'
const LatexConfig = {
    loader: { load: ['[tex]/html'] },
    tex: {
        packages: { '[+]': ['html'] },
        inlineMath: [
            ['$', '$'],
            ['\\(', '\\)']
        ],
        displayMath: [
            ['$$', '$$'],
            ['\\[', '\\]']
        ]
    }
}

function App() {
    return (
        <MathJaxContext version={3} config={LatexConfig}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="destory" element={<Destroy />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="user" element={<User />} />
                    <Route path="bind" element={<Bind />} />
                    <Route path="words" element={<Words />} />
                    <Route path="vocabulary" element={<Vocabulary />} />
                    <Route path="error_questions" element={<ClientErrorQuestions />} />
                    <Route path="redirect" element={<Redirect />} />
                    <Route path="*" exact={true} component={NotFound} />
                </Routes>
            </BrowserRouter>
        </MathJaxContext>
    )
}

export default App

