/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2024/1/10
 */

import { useCallback, useEffect, useState } from 'react'
import styles from './index.module.less'
import { Picker } from "antd-mobile"
import api from "@/api"

const basicColumns = [
    [
        { label: '小学', value: 'Mon' },
        { label: '初中', value: 'Tues' },
        { label: '周三', value: 'Wed' },
        { label: '周四', value: 'Thur' },
        { label: '周五', value: 'Fri' },
    ]
]

function Index(props) {
    const [open, setOpen] = useState(false)
    const [list, setList] = useState([])
    const handleImport = useCallback(async (_val) => {
        if (_val.length) {
            let _id = _val[0]
            if (_id) {
                const res = await api.putImport({ tag_id: _id })
                if (res.meta.code === 200) {
                    window.location.reload()
                }
            }
        }
    }, [])

    const handleOpen = useCallback(async () => {
        const res = await api.getWordTags()
        console.log(res.data)
        const _list = []
        res.data.forEach((_item) => {
            _list.push({
                label: `${_item.name} [${_item.word_count}]`,
                value: _item.id
            })
        })
        setList([_list])
        setOpen(true)
    }, [])


    useEffect(() => {
    }, [])
    return <><span className={styles.btn} onClick={handleOpen}>单词表</span><Picker
            columns={list}
            visible={open && list.length}
            onClose={() => {
                setOpen(false)
            }}
            // value={value}
            onConfirm={v => {
                handleImport(v)
            }}
        />
    </>
}

export default Index
