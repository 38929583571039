/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2019-10-29
 */

import axios from 'axios'
import { Toast } from 'antd-mobile'
import helper from '../utils/helper'
import config from '../config'

const baseURL = config.apiHost
// if (process.env.NODE_ENV === 'development') {
//     baseURL = 'http://dev-api.momo.im/admin/proxy/internal?uri='
// }

export const request = axios.create({
    timeout: 60000,
})
// Add a request interceptor
request.interceptors.request.use(function(config) {
    const query = helper.parseParams()
    config.headers['Authorization'] = `Bearer ${query.token || ''}`
    return config
}, function(error) {
    // Do something with request error
    return Promise.reject(error)
})
// Add a response interceptor
request.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 200) {
        if (response.data) {
            return response.data
        } else {
            alert('server error')
        }
    } else {
        return response
    }
}, function(error) {
    // console.log('error', arguments)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const res = error.response
    if (res && res.data && res.data.meta) {
        Toast.show({
            content: res.data.meta.message || error.message || 'network error connection refused'
        })
        return res.data
        // alert(res.data.meta.message)
    } else {
        Toast.show({
            duration: 0,
            content: error.message || 'network error connection refused'
        })
        return Promise.reject(error)
    }
})
export const formatUrl = (url, query) => {
    if (query) {
        url = helper.format(url, query)
    }
    if (!url.startsWith('http')) {
        url = `${baseURL}${url}`
    }
    return url
}

export default {
    // 错题列表
    getErrorQuestions: async (params = {}) => {
        return request.get(formatUrl('/api/error_questions'), { params })
    },
    getMe: async () => {
        return request.get(formatUrl('/api/user/me'), {})
    }
}


