import dayjs from 'dayjs'

/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/11/25
 */
export default {
    formatTime: (_t) => {
        if (_t) {
            if (_t > 60) {
                _t = `${(_t / 60).toFixed(0)}分钟`
            } else {
                _t = `${_t.toFixed(0)}秒`
            }
            return _t
        }
        return ''
    },
    formatTimestamp(timestamp, format = 'YYYY-MM-DD') {
        if (timestamp) {
            timestamp = timestamp > 10000000000 ? timestamp : timestamp * 1000
            return dayjs(timestamp).format(format)
        }
        return '--'
    },
    getDataRange: (flag = 'week') => {
        let res = { start_date_id: 0, end_date_id: 0 }
        let offset = dayjs().day() - 1
        if (flag === 'week') {
            if (dayjs().day() === 0) {
                // 周日
                offset = 6
            }
            const date = dayjs().subtract(offset, 'day')
            res.start_date_id = date.format('YYYYMMDD')
            res.end_date_id = dayjs().format('YYYYMMDD')
            return res
        } else if (flag === 'month') {
            res.start_date_id = dayjs().startOf('month').format('YYYYMMDD')
            res.end_date_id = dayjs().endOf('month').format('YYYYMMDD')
            return res
        }
    }
}
