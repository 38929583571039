/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2024/1/10
 */

import { useEffect } from 'react'

function Index(props) {
    useEffect(() => {
    }, [])
    return <div>
        404 Not Found
    </div>
}

export default Index
