/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2019-10-29
 */

import axios from 'axios'
import { Toast } from 'antd-mobile'
import helper from '../utils/helper'
import config from '../config'

const baseURL = config.apiHost
// if (process.env.NODE_ENV === 'development') {
//     baseURL = 'http://dev-api.momo.im/admin/proxy/internal?uri='
// }

export const request = axios.create({
    timeout: 60000,
})
// Add a request interceptor
request.interceptors.request.use(function(config) {
    // Do something before request is sent
    let accessToken = localStorage.getItem('access_token')
    if (process.env.NODE_ENV === 'development') {
        // 本地开发写死token
        // 1625 对应用户ID:287635145046241
        accessToken = 'WyJhdXRob3JpemF0aW9uX2NvZGUiLDQsOCw0LDE2NjE2NzgwNzIsMF0.Ywsx-A.SFFjCkVal8Efp6vLohvsKNcAq58'
        // accessToken = 'WyJhdXRob3JpemF0aW9uX2NvZGUiLDQsOCwyLDE2NjExNDg5ODIsMF0.YwMfNg.e1vQhjrlRikatKPg2WiasKV5YO4'
        // wing
        // accessToken = 'WyJhdXRob3JpemF0aW9uX2NvZGUiLDQsOCwzLDE3MDQ0NTg1NjAsMF0.ZZf5QA.2PS7UvvtjYls8lWzoaYZ3--IYcM'

        // 小敏有数据 需手动修改绑定用户IDa 287627751122876
        // accessToken = 'WyJhdXRob3JpemF0aW9uX2NvZGUiLDQsOCw1LDE2NjE3NTE4NjUsMF0.YwxSOQ.h8XQWy4PkI4h_lNpNZoWqA9Idog'
    }
    // console.log(accessToken)
    config.headers['Authorization'] = `Bearer ${accessToken}`
    return config
}, function(error) {
    // Do something with request error
    return Promise.reject(error)
})
// Add a response interceptor
request.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 200) {
        if (response.data) {
            return response.data
        } else {
            alert('server error')
        }
    } else {
        return response
    }
}, function(error) {
    // console.log('error', arguments)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const res = error.response
    if (res && res.data && res.data.meta) {
        if (res.data.meta.code === 401 || res.data.meta.code === 101054) {
            // 未登录
            window.localStorage.removeItem('access_token')
            let query = ''
            if (window.location.href.endsWith('/words')) {
                query = '?page=words'
            }
            if (window.location.href.endsWith('/vocabulary')) {
                query = '?page=vocabulary'
            }
            window.location.href = `/user${query}`
        } else {
            Toast.show({
                content: res.data.meta.message || error.message || 'network error connection refused'
            })
        }
        return res.data
        // alert(res.data.meta.message)
    } else {
        Toast.show({
            duration: 0,
            content: error.message || 'network error connection refused'
        })
        return Promise.reject(error)
    }
})
export const formatUrl = (url, query) => {
    if (query) {
        url = helper.format(url, query)
    }
    if (!url.startsWith('http')) {
        url = `${baseURL}${url}`
    }
    return url
}

export default {
    // 登录
    getAuthorizeUrl: async (params = {}) => {
        return request.get(formatUrl('/wechat/public/oauth/authorize_url'), { params })
    },
    postToken: async (data) => {
        return request.post(formatUrl('/wechat/api/auth/token'), data)
    },
    getToken: async () => {
        return request.get(formatUrl('/wechat/api/auth/token'))
    },
    getMe: async () => {
        return request.get(formatUrl('/wechat/api/users/me'))
    },
    getBindUsers: async () => {
        return request.get(formatUrl('/wechat/api/bind_users'))
    },
    getWords: async () => {
        return request.get(formatUrl('/wechat/api/words'))
    },
    getWordTags: async () => {
        return request.get(formatUrl('/wechat/api/words/tags'))
    },
    getWordsSummary: async () => {
        return request.get(formatUrl('/wechat/api/words/summary'))
    },
    getWordsStat: async () => {
        return request.get(formatUrl('/wechat/api/words/stat'))
    },
    putWords: async (data) => {
        return request.put(formatUrl('/wechat/api/words'), data)
    },
    putImport: async (data) => {
        return request.put(formatUrl('/wechat/api/words/import'), data)
    },
    putWordsRestart: async (data) => {
        return request.put(formatUrl('/wechat/api/words/start'), data)
    },
    putFinish: async (data) => {
        return request.put(formatUrl('/wechat/api/words/finish'), data)
    },
    postBindUser: async (data) => {
        return request.post(formatUrl('/wechat/api/bind_users'), data)
    },
    getStatesByUserID: async (user_id, params = {}) => {
        return request.get(formatUrl(`/wechat/api/stats/users/${user_id}/summary`), { params })
    },
    getStatesTodayByUserID: async (user_id, params = {}) => {
        return request.get(formatUrl(`/wechat/api/stats/users/${user_id}/today_user_tasks`), { params })
    },
    getLevelTasksStatesByUserID: async (user_id, params = {}) => {
        return request.get(formatUrl(`/wechat/api/stats/users/${user_id}/user_level_tasks`), { params })
    },
    getReviewTasksByUserID: async (user_id, params = {}) => {
        return request.get(formatUrl(`/wechat/api/stats/users/${user_id}/review_tasks`), { params })
    },
    getStatesUser: async (params = {}) => {
        return request.get(formatUrl('/wechat/api/stats/user'), { params })
    },
    getReviewTasks: async (params = {}) => {
        return request.get(formatUrl('/wechat/api/stats/review_tasks'), { params })
    },
    getSubjects: async (params = {}) => {
        return request.get(formatUrl('/wechat/api/const/subjects'), { params })
    },
    userBind: async (data) => {
        return request.post(formatUrl('/wechat/api/users/bind'), data)
    },
    // putBooks: async (id, data) => {
    //     return request.put(formatUrl('/books/{id}', { id }), data)
    // },
    // deleteBooks: async (id) => {
    //     return request.delete(formatUrl(`/books/${id}`), { data: {} })
    // },
}


