/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */

import styles from './index.module.less'

function Index() {
    return (
        <div className={styles.destory}>
            <h4>风险提示</h4>
            <p>注销前请认真阅读以下重要提醒。当您按照注销提示填写并提交注销申请时，即表示您已充分阅读、理解并同意本风险提示的全部内容。如您不同意此风险提示的相关条款和约定，请您及时停止注销行为。</p>
            <p>帐号注销后，您将无法再使用该帐号，包括但不限于：</p>
            <h5>1.无法登录</h5>
            <p>一旦注销，您将无法使用该帐号登录西瓜学习。注销仅对西瓜学习相关的服务平台生效。</p>
            <h5>2.信息抹除，无法恢复</h5>
            <p>注销后将永久抹除西瓜学习相关平台的所有信息且无法找回，包括但不限于头像、个人资料、认证身份等。</p>
            <p>如果确定注销帐号，可以发送邮件至<a href="mailto:support@tomatolearn.com">support@tomatolearn.com</a> ，我们会尽力帮您解决问题。</p>
        </div>
    )

}

export default Index
