/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Dropdown } from 'antd-mobile'
import styles from './index.module.less'
import { Link } from "react-router-dom"
import { useCallback, useRef } from "react"
import helper from "../../../../utils/helper"

function Index(props) {
    const { users, currentUser, onChange } = props
    const dropdownRef = useRef(null)
    const handleClick = useCallback((_user) => {
        helper.setCurrentUserID(_user.id)
        dropdownRef.current.close()
        onChange(_user)
    }, [onChange])
    return <div className={styles.header}>
        <Link className={styles.add} to="/bind">添加</Link>
        学习数据
        <div className={styles.name}>
            <Dropdown ref={dropdownRef}>
                <Dropdown.Item key="sorter" title={currentUser?.name}>
                    <ul className={styles.selectUser}>
                        {users.map((_user) => {
                            return <li key={_user.id} onClick={() => {
                                handleClick(_user?.user)
                            }}>{_user.user.name}</li>
                        })}
                    </ul>
                </Dropdown.Item>
            </Dropdown>
        </div>
    </div>
}

export default Index
