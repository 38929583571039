/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2023/4/7
 */
import styles from './index.module.less'
import constants from '../../constants'
import { useMemo } from 'react'

function Index(props) {
    const { user_name, data, question_type } = props
    const _dom = useMemo(() => {
        const _res = []
        if ([1, 32].includes(question_type)) {
            if (data.sub_questions.length) {
                data.sub_questions.forEach((_item, _idx) => {
                    _res.push(<span key={_idx}
                                    data-correct={_item.correct}>{constants.letter[_item.answer] || _item.answer}{_idx === data.sub_questions.length - 1 ? '' : '、'}</span>)
                })
            } else {
                _res.push(<span key={question_type}
                                data-correct={data.correct}>{constants.letter[data.answer] || data.answer}</span>)
            }
        } else {
            if (data.sub_questions.length) {
                data.sub_questions.forEach((_item, _idx) => {
                    _res.push(<span key={_idx}
                                    data-correct={_item.correct}>{_item.answer}{_idx === data.sub_questions.length - 1 ? '' : '、'}</span>)
                })
            } else {
                if (data.answer.indexOf(constants.splitSyn) !== -1) {
                    const _answer = data.answer.split(constants.splitSyn)
                    _answer.forEach((_item, _idx) => {
                        if (_idx === _answer.length - 1) {
                            _res.push(<span key={_idx} data-correct={data.correct_list[_idx]}>{_item}</span>)
                        } else {
                            _res.push(<span key={_idx} data-correct={data.correct_list[_idx]}>{_item}、</span>)
                        }
                    })
                } else {
                    _res.push(<span key={question_type} data-correct={data.correct}>{data.answer}</span>)
                }
            }
        }
        return <div>{user_name ? `${user_name}：` : '你的回答：'}{_res}</div>
    }, [data, user_name, question_type])
    return <div className={styles.answer} data-correct={data.correct}>{_dom}</div>
}

export default Index
