/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Card, Toast } from 'antd-mobile'
import styles from './index.module.less'
import { useCallback, useEffect, useMemo, useState, } from "react"
import api from "@/api"
import helper from '@/utils/helper'
import utils from '../../utils'

function Index(props) {
    const { currentUser } = props
    const [active, setActive] = useState('day')
    const [summary, setSummary] = useState({})
    const [duration, setDuration] = useState(0)
    const loadSummary = useCallback(async (flag = '') => {
        setActive(flag)
        let params = {}
        let tip = Toast.show({
            icon: 'loading',
            content: 'loading',
        })
        params = { ...utils.getDataRange(flag) }
        params.field = 'knowledge_stat,question_stat,course_stat,repeat_stat'
        const res = await api.getStatesByUserID(helper.getCurrentUserID(), params)
        if (res.data) {
            let _summary = res.data
            let _duration = _summary.course_stat.course_duration + _summary.repeat_stat.repeat_duration + _summary.knowledge_stat.knowledge_duration + _summary.question_stat.question_duration
            setSummary(_summary)
            setDuration(_duration)
        }
        tip.close()
    }, [])

    useEffect(() => {
        setActive('day')
    }, [currentUser])

    const filter = useMemo(() => {
        return <div className={styles.filter}>
             <span className={active === 'day' ? styles.active : ''} onClick={() => {
                 loadSummary('day')
             }}>今日</span>|
            <span className={active === 'week' ? styles.active : ''} onClick={() => {
                loadSummary('week')
            }}>本周</span>|
            <span className={active === 'month' ? styles.active : ''} onClick={() => {
                loadSummary('month')
            }}>本月</span>
        </div>
    }, [active, loadSummary])
    return <div className={styles.summary}>
        <Card title="概况" extra={filter}>
            <ul>
                <li>
                    <div className={styles.label}>课程</div>
                    <div className={styles.num}>{summary.course_stat?.course_count || 0}</div>
                </li>
                <li>
                    <div className={styles.label}>跟读</div>
                    <div className={styles.num}>{summary.repeat_stat?.repeat_count || 0}</div>
                </li>
                <li>
                    <div className={styles.label}>卡片</div>
                    <div className={styles.num}>{summary.knowledge_stat?.knowledge_reviewed_count || 0}</div>
                </li>
                <li>
                    <div className={styles.label}>刷题</div>
                    <div className={styles.num}>{summary.question_stat?.question_count || 0}</div>
                </li>
                <li>
                    <div className={styles.label}>学习时长</div>
                    <div className={styles.num}>{helper.formatDuration(duration)}</div>
                </li>
            </ul>
        </Card>
    </div>
}

export default Index
