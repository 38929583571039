const constants = {
    letter: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
    splitSyn: '❗',
    subject: {
        "1": "语文",
        "2": "数学",
        "3": "英语",
        "4": "道德与法治",
        "5": "历史",
        "6": "物理",
        "7": "化学",
        "8": "地理",
        "9": "生物",
        "99": "其他"
    }
}

export default constants
