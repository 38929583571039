/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/3
 */

import { useMemo } from 'react'
import constants from '../../constants'
import helper from '../../utils/helper'
import { MathJax } from 'better-react-mathjax'
import styles from './index.module.less'

const formatTitle = (title, correct) => {
    let _title = title.replace(/\n/g, '<br />')
    if (correct.length) {
        const re = /<input /g
        let count = 0  // 计数器
        _title = _title.replace(re, function(match, index, _s, _i) {
            return '<input data-correct="' + correct[count++] + '" '
        })
    }
    return _title
}

function Index(props) {
    const { record = {}, className = '', index = '', showID, correct_list = [] } = props

    const _answer = useMemo(() => {
        let _ans = ''
        if (`${record.answer}`.startsWith('http')) {
            _ans = <div className={styles.ccAnswer}>答案：<img src={record.answer} /></div>
        } else if (`${record.answer}`.indexOf('|') !== -1) {
            if (`${record.answer}`.indexOf('==') !== -1) {
                _ans = <div className={styles.ccAnswer}>答案：{record.answer}</div>
            } else {
                let _arr = record.answer.split('|')
                _ans = <div className={styles.ccAnswer}>答案：{_arr[0] === '1' ? '正确' : '错误'}
                    &nbsp;&nbsp; 理由：
                    <span dangerouslySetInnerHTML={{ __html: _arr[1] }}></span>
                </div>
                // } else if (`${record.answer}`.indexOf(',') !== -1) {
            }
        } else if (record.answer) {
            let _s = helper.getAnswerStr(record)
            if (_s.indexOf('undefined') === -1) {
                let _str = helper.getAnswerStr(record)
                if (_str.indexOf('$') !== -1) {
                    _ans = <div className={styles.ccAnswer}>答案：
                        <MathJax dynamic inline>
                            <span dangerouslySetInnerHTML={{ __html: _str }}></span>
                        </MathJax>
                    </div>
                } else {
                    _ans = <div className={styles.ccAnswer}>答案：
                        <span dangerouslySetInnerHTML={{ __html: _str }}></span>
                    </div>
                }
            } else {
                if (record.answer.indexOf('$') !== -1) {
                    _ans = <div className={styles.ccAnswer}>答案：
                        <MathJax dynamic inline>
                            <span dangerouslySetInnerHTML={{ __html: record.answer }}></span>
                        </MathJax>
                    </div>
                } else {
                    _ans = <div className={styles.ccAnswer}>答案：
                        <span dangerouslySetInnerHTML={{ __html: record.answer }}></span>
                    </div>
                }
            }
        }
        return _ans
    }, [record])

    if (record?.title?.indexOf('<input') !== -1 && record?.title?.indexOf('<input readonly') === -1) {
        record.title = record.title.replace(/<input/g, '<input readonly')
    }


    return <> {record?.title ?
        <div className={`${styles.exContent} ${className}`}>
            <div
                className={styles.title}>{index}{record.is_gpt ? <span
                className={styles.is_gpt}>(主观题)</span> : ''}题目{showID ? record.score ? `(${record.score}分)` : '' : ''}：
                {record.title.indexOf('$') !== -1 ?
                    <MathJax dynamic inline>
                        <span dangerouslySetInnerHTML={{ __html: formatTitle(record.title, correct_list) }}></span>
                    </MathJax>
                    :
                    <span dangerouslySetInnerHTML={{ __html: formatTitle(record.title, correct_list) }}></span>
                }
            </div>
            {record.audio_url ?
                <audio src={record.audio_url} controls preload="none" />
                : ''}
            {record.extra ?
                <div>原文：<span
                    dangerouslySetInnerHTML={{ __html: record.extra.replace(/\n/g, '<br />') }}></span>
                </div>
                : ''}
            {record.options?.length ?
                <div>
                    选项：
                    {record.options?.map((op, idx) => {
                        if (op.indexOf('$') !== -1) {
                            return <MathJax dynamic inline>
                            <span key={idx}>{constants.letter[idx]}、<span key={idx}
                                                                          dangerouslySetInnerHTML={{ __html: op.replace(/\n/, '<br />') }}></span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </MathJax>
                        } else {
                            return <span key={idx}>{constants.letter[idx]}、<span key={idx}
                                                                                 dangerouslySetInnerHTML={{ __html: op.replace(/\n/, '<br />') }}></span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        }
                    })}
                </div>
                : ''}

            {record.sub_questions?.length ?
                record.sub_questions.map((item, idx) => {
                    return <div className={styles.sub} key={idx}>
                        <div className={styles.title}>小题{idx + 1}{item.score ? `(${item.score}分)` : ''}：
                            <span
                                dangerouslySetInnerHTML={{ __html: item.title.replace(/\n/g, '<br />') }}></span>
                        </div>
                        <div className={styles.ccOption}>选项{idx + 1}：
                            {item.options.map((_op, _id) => {
                                return <span key={_id}>{constants.letter[_id]}、<span
                                    dangerouslySetInnerHTML={{ __html: _op }}></span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            })}
                        </div>
                        <div className={styles.ccAnswer}>答案{idx + 1}：
                            <span
                                className={styles.strong}
                                dangerouslySetInnerHTML={{ __html: helper.getAnswerStr(item) }}></span>
                        </div>
                    </div>
                })
                :
                _answer
            }
            {record.origin_answer ? <div>原答案： <img src={record.origin_answer} /></div> : ''}
            {record.origin_parse ?
                record.origin_parse.indexOf('$') !== -1 ?
                    <div><span className={styles.top}>原解析：</span>
                        <MathJax dynamic inline>
                            <span
                                dangerouslySetInnerHTML={{ __html: record.origin_parse.replace(/\n/g, '<br />') }}></span>
                        </MathJax>
                    </div>
                    :
                    <div><span className={styles.top}>原解析：</span>
                        <span dangerouslySetInnerHTML={{ __html: record.origin_parse.replace(/\n/g, '<br />') }}></span>
                    </div>
                : ''}
        </div>
        : ''}
    </>
}

export default Index
