/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Card, Toast, Empty } from 'antd-mobile'
import styles from './index.module.less'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Line } from '@ant-design/plots'
import api from "../../../../api"
import utils from '../../utils'
import helper from "../../../../utils/helper"


function Index(props) {
    const { stats, currentUser } = props
    const [data, setData] = useState([])
    const [active, setActive] = useState('week')
    const formatData = useCallback((_data) => {
        if (_data.length) {
            let _res = []
            _data.forEach((item) => {
                _res.push({
                    name: `${item.date_id}`.substring(6),
                    value: item.total_duration / 1000
                })
            })
            setData(_res)
        }
    }, [])

    useEffect(() => {
        if (stats) {
            formatData(stats)
        }
        return () => {
        }
    }, [stats])


    const config = useMemo(() => {
        return {
            data,
            xField: 'name',
            yField: 'value',
            height: 200,
            tooltip: false,
            yAxis: false
            // label: {
            //     content: (item) => {
            //         return `${formatTime(+item?.value)}`
            //     }
            // }
        }

    }, [data])
    const loadStat = useCallback(async (flag) => {
        setActive(flag)
        let params = {}
        let tip = Toast.show({
            icon: 'loading',
            content: 'loading'
        })
        params = { ...utils.getDataRange(flag) }
        params.field = 'duration_stats'


        // const res = await api.getStatesUser(params)
        const res = await api.getStatesByUserID(helper.getCurrentUserID(), params)
        if (res.data) {
            formatData(res.data.duration_stats)
        }
        tip.close()
    }, [formatData])
    useEffect(() => {
        setActive('week')
    }, [currentUser])

    const filter = useMemo(() => {
        return <div className={styles.filter}>
            <span className={active === 'week' ? styles.active : ''} onClick={() => {
                loadStat('week')
            }}>本周</span>|
            <span className={active === 'month' ? styles.active : ''} onClick={() => {
                loadStat('month')
            }}>本月</span>
        </div>
    }, [active, loadStat])
    return <div className={styles.summary}>
        <Card title="学习时长走势" extra={filter}>
            <div className={styles.chart}>
                {data.length ? <Line {...config} /> : <Empty description="暂无数据" />}
            </div>
        </Card>
    </div>
}

export default Index
