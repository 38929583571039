/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Card, Toast } from 'antd-mobile'
import styles from './index.module.less'
import { useCallback, useEffect } from "react"
import api from "@/api"
import { useState } from "react"
import helper from "@/utils/helper"

const statusKV = {
    0: '未完成',
    1: '已完成'
}

function Index(props) {
    const { currentUser } = props
    const [detail, setDetail] = useState(null)
    const loadData = useCallback(async () => {
        let tip = Toast.show({
            icon: 'loading',
            content: 'loading',
        })
        const res = await api.getStatesTodayByUserID(helper.getCurrentUserID())
        if (res.data) {
            setDetail(res.data)
        }
        tip.close()
    }, [])

    useEffect(() => {
        loadData()
    }, [currentUser])


    return <div className={styles.summary}>
        <Card title="今日任务" extra={
            <div className={styles.finishNum}>{detail?.summary ? `${detail.summary.finish_count}/${detail.summary.total_count}` : ''}</div>}>
            <ul>
                {detail?.user_tasks?.map((item, idx) => {
                    return <li status={item.finish_status} key={idx}>
                        <div className={styles.label}>{item.task_name}你好地在要要要工工</div>
                        <div className={styles.num}>剩余:{item.task_count - item.finish_count || 0}</div>
                        <div className={styles.status}><span>{statusKV[item.finish_status]}</span></div>
                    </li>
                })}
            </ul>
        </Card>

    </div>
}

export default Index
