/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Calendar, Card, Toast } from 'antd-mobile'
import styles from './index.module.less'
import { useCallback, useEffect, useState } from "react"
import dayjs from 'dayjs'
import api from "@/api"
import helper from "@/utils/helper"

function Index(props) {
    const { currentUser } = props
    const [status, setStatus] = useState({})
    const [render, setRender] = useState(false)
    const loadDate = useCallback(async (params) => {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        const res = await api.getStatesByUserID(helper.getCurrentUserID(), params)
        if (res.data.user_tasks) {
            let _status = {}
            res.data.user_tasks.forEach((_item) => {
                _status[_item.date_id] = _item
            })
            setRender(false)
            setStatus(_status)
            setRender(true)
        }
        Toast.clear()
    }, [])

    const handleChange = useCallback((year, month) => {
        const startOfMonth = dayjs(`${year}-${month}-01`).startOf('month').format('YYYYMMDD')
        const endOfMonth = dayjs(`${year}-${month}-01`).endOf('month').format('YYYYMMDD')
        loadDate({ start_date_id: startOfMonth, end_date_id: endOfMonth, field: 'user_tasks' })
    }, [loadDate])

    useEffect(() => {
        loadDate()
    }, [currentUser])
    return <div className={styles.cal}>
        <Card title="">
            {render ?
                <Calendar
                    onPageChange={handleChange}
                    renderLabel={date => {
                        const dateId = dayjs(date).format('YYYYMMDD')
                        if (status[dateId]?.task_status === 1) {
                            return <span className={styles.status1}></span>
                        } else if (status[dateId]?.task_status === 2) {
                            return <span className={styles.status2}></span>
                        } else if (status[dateId]?.task_status === 3) {
                            return <span className={styles.status3}></span>
                        }
                    }}
                />
                : ''}
        </Card>
    </div>
}

export default Index

