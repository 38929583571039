/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import api from "../../../api/client"
import { Button, Toast, Empty, Selector } from "antd-mobile"
import styles from './index.module.less'
import helper from '../../../utils/helper'
import Summary from "../../../components/QuestionSummary"
import Answer from "../../../components/Answer"
import constants from "../../../constants"

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
        const cls = currentPage === i ? 'primary' : 'default'
        pages.push(
            <Button size="small" color={cls} key={i} onClick={() => onPageChange(i)}>{i}</Button>
        )
    }
    return (<> {pages} </>)
}

const filterTime = [
    {
        label: '错题时间降序',
        value: '-error_time',
    },
    {
        label: '错题时间升序',
        value: 'error_time',
    }
]
const filterCount = [
    {
        label: '错题次数降序',
        value: '-error_count',
    },
    {
        label: '错题次数升序',
        value: 'error_count',
    }
]

function Index() {
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(0)
    const [list, setList] = useState([])
    const [params, setParams] = useState({
        order_by: '',
        limit: 10,
        offset: 0
    })
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const filterRef = useRef(null)

    const loadData = useCallback(async (_params) => {
        setLoading(true)
        let tip = Toast.show({
            icon: 'loading',
            content: 'loading',
        })
        if (!_params.order_by) {
            delete _params.order_by
        }
        const res = await api.getErrorQuestions(_params)
        if (res.data) {
            setList(res.data)
            setTotal(res?.pagination?.rows_found || 0)
        }
        tip.close()
        setLoading(false)
        setTimeout(() => {
            if (filterRef.current) {
                filterRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
            }
        }, 300)
    }, [])

    useEffect(() => {
        loadData(params)
        return () => {
        }
    }, [])
    const handlePageChange = useCallback(async (page) => {
        const _params = { ...params, offset: params.limit * (page - 1) }
        setCurrent(page)
        setParams(_params)
        loadData(_params)
    }, [loadData, params])

    const handleFilter = useCallback(async (subject_id) => {
        setActive(subject_id)
        const _params = { ...params }
        if (subject_id) {
            _params.subject_id = subject_id
        } else {
            delete _params.subject_id
        }
        loadData(_params)
        setParams(_params)
    }, [loadData, params])

    const handleFilterOrder = useCallback(async (arr, _type) => {
        const _params = { order_by: '', ...params }
        _params.order_by = _params.order_by.replace('-' + _type, '')
        _params.order_by = _params.order_by.replace(_type, '')
        let _f = _params.order_by.split(',')
        if (arr.length) {
            _f.push(arr[0])
        }
        _params.order_by = _f.filter((s) => s).join(',')
        loadData(_params)
        setParams(_params)
    }, [loadData, params])


    const buttons = useMemo(() => {
        const _subjects = [{ id: 0, name: '全部' }]
        for (let _k in constants.subject) {
            _subjects.push({ id: +_k, name: constants.subject[_k] })
        }
        _subjects.sort((a, b) => a.id - b.id)
        const _buttons = []
        _subjects.forEach((_item) => {
            let color = 'default'
            if (active === _item.id) {
                color = 'primary'
            }
            _buttons.push(<Button color={color} className={styles.btn} key={_item.id}
                                  onClick={() => handleFilter(_item.id)}>{_item.name}</Button>)

        })
        return _buttons
    }, [active, handleFilter])


    return <div className={styles.questions}>
        <div className={styles.filter} ref={filterRef}>
            <div className={styles.subjects}>
                {buttons}
            </div>
            <div className={styles.total}>
                <span>总数：{total}</span>
                <Selector options={filterTime} onChange={(arr) => handleFilterOrder(arr, 'error_time')} />
                <Selector options={filterCount} onChange={(arr) => handleFilterOrder(arr, 'error_count')} />
            </div>
        </div>
        {list.map((_item, _idx) => {
            return <div key={_idx} className={styles.summaryWrap}>
                <Summary index={`${_idx + 1}、`} className={styles.question} showID={true}
                         record={_item.question} />
                <div className={styles.tipWrap}>
                    <div className={styles.answer}>
                        {_item.user_question ?
                            <Answer user_name={_item.user?.name} data={_item.user_question}
                                    question_type={_item.question.question_type_id} />
                            : ''}
                    </div>
                </div>
                <div className={styles.tip}>
                    <span>{helper.getSubjectName(_item.subject_id)}</span>
                    <span className={styles.errorNum}>错题次数：{_item.error_count}</span>
                    <span className={styles.time}>{helper.formatDateTime(_item.error_time, 'YYYY-MM-DD')}</span>
                </div>
            </div>
        })}
        {!list.length && !loading ? <Empty description="暂无数据" /> : ''}
        <div className={styles.pagination}>
            <Pagination
                currentPage={current}
                totalPages={Math.ceil(total / params.limit)}
                onPageChange={handlePageChange}
            />
        </div>
    </div>
}

export default Index
