/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect } from 'react'
import api from "../../api"
import helper from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import { Toast } from "antd-mobile"

function Index(props) {
    const navigate = useNavigate()
    const checkAccessToken = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const params = helper.parseParams(window.location.search)
        if (params.code) {
            const res = await api.postToken({ 'grant_type': 'authorization_code', 'authorization_code': params.code })
            if (res.data?.access_token) {
                window.localStorage.setItem('access_token', res.data.access_token)
                const params = helper.parseParams()
                if (params.page) {
                    navigate(`/${params.page}`)
                } else {
                    navigate('/dashboard')
                }
            }
        }
        tip.close()
    }, [])

    useEffect(() => {
        checkAccessToken()
        return () => {
        }
    }, [])

    return <div></div>

}

export default Index
