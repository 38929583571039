/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */

import { useCallback, useEffect, useRef, useState } from "react"
import { Toast, Button } from 'antd-mobile'
import styles from './index.module.less'
import api from "@/api"
import helper from "../../utils/helper"
import Import from './import'

function Index() {
    const [list, setList] = useState([])
    const [stat, setStat] = useState({})
    const [cnIndex, setCnIndex] = useState([])
    const [active, setActive] = useState(null)
    const [savedIndex, setSavedIndex] = useState([])
    const [first, setFirst] = useState(false)
    const [finish, setFinish] = useState(false)
    const audioRef = useRef(null)
    const lastScrollTopRef = useRef(0)
    const timeoutRef = useRef(0)

    const loadStat = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.getWordsStat()
        setStat(res.data)
        if (res.data.wechat_user_word_round.finish_status) {
            setFinish(true)
        }
        tip.close()
        return res
    }, [])
    const loadData = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const _res = await loadStat()
        if (_res.data.total_count) {
            const res = await api.getWords()
            setList(res.data) // .splice(0, 10)
        } else {
            setFirst(true)
        }
        tip.close()
    }, [loadStat])

    const handleScroll = useCallback(async () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        // 向上滚动
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(async () => {
            if (scrollTop < lastScrollTopRef.current - 50) {
                const res = await api.getWordsSummary()
                Toast.show({
                    content:
                        <div>已学：{res.data.total_learn_count}&nbsp;&nbsp;&nbsp;剩余：{res.data.total_count - res.data.total_learn_count}</div>,
                    position: 'top',
                })
            }
            lastScrollTopRef.current = scrollTop
        }, 500)
    }, [])


    useEffect(() => {
        document.title = '闪电单词'
        loadData().then()
        // loadSummary().then()
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handlePlay = useCallback((src) => {
        if (audioRef.current && src) {
            audioRef.current.setAttribute('src', src)
        }
    }, [])

    const handleSave = useCallback(async (item) => {
        const _index = list.findIndex((w) => w.word === item.word)
        if (_index !== -1) {
            // handlePlay(item.audio_url)
            setSavedIndex(savedIndex.concat(_index))
            if (item.word) {
                const res = await api.putWords({ word: item.word })
                const _list = helper.deepCopy(list)
                _list[_index] = { ...res.data }
                setList(_list)
            }
        }

    }, [savedIndex, list])

    const handleFinish = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const res = await api.putFinish()
        if (res.meta.code === 200) {
            await loadStat()
        }
        tip.close()
    }, [loadStat])

    const handleRestart = useCallback(async () => {
        const res = await api.putWordsRestart({ remember: 0 })
        if (res.meta.code === 200) {
            window.location.reload()
        }
    }, [])


    const handleClick = useCallback((item) => {
        handlePlay(item.audio_url)
        setActive(item)
        const _index = list.findIndex((w) => w.word === item.word)
        if (_index !== -1) {
            setCnIndex(cnIndex.concat(_index))
        }
    }, [handlePlay, list, cnIndex])

    return (
        <>
            <Import />
            <div className={styles.header}>闪电单词</div>
            <div className={styles.list}>
                <audio src="" autoPlay ref={audioRef}></audio>
                {list.map((item, idx) => {
                    return <div key={idx} className={styles.item} onClick={() => handleClick(item)}>
                        <span className={styles.en}>{item.word}</span>
                        {cnIndex.includes(idx) ? <>
                            <span className={styles.cn}>{item.translation}</span>
                            <span className={styles.score}>{item.score}</span>
                            {
                                savedIndex.includes(idx) ? '' :
                                    <Button className={styles.button} onClick={() => handleSave(item)}>不认识</Button>
                            }
                        </> : ''}

                    </div>
                })}
                {first ? <div className={styles.first}>
                    点击右上角"<strong>单词表</strong>"导入词库！
                </div> : ''}
                <div className={styles.footer}>
                    {finish ? <div className={styles.summary}>
                        <p>恭喜你，完成本轮学习</p>
                        <p><Button className={styles.btn} block color="primary" onClick={handleRestart}>再学一遍</Button>
                        </p>
                        <p>已记住：<strong>{stat.total_remember_count || 0}</strong></p>
                        {/*<p>不认识：<strong>{summary.total_to_remember_count || 0}</strong></p>*/}
                    </div> : first ? '' : <Button block className={styles.btn} color="primary"
                                                  onClick={handleFinish}>完成本轮学习</Button>}

                </div>
            </div>
        </>
    )

}

export default Index
