import { memo, useCallback } from 'react'
import { Form, Input, Button, Toast } from 'antd-mobile'

import styles from './index.module.less'
import api from "../../api"

export default memo(function Bind(props) {
    const handleFinish = useCallback(async (values) => {
        const res = await api.postBindUser(values)
        if (res.meta?.code === 200) {
            Toast.show({
                icon: 'success',
                content: '绑定成功!',
            })
            props.onBind(res.data)
        } else {
            Toast.show({
                icon: 'fail',
                content: res.meta.message,
            })
        }
    }, [props])
    return (
        <div className={styles.bind}>
            <h3>请输入学生的信息进行绑定</h3>
            <div className={styles.formWrap}>
                <Form
                    name="form"
                    onFinish={handleFinish}
                    footer={
                        <Button block type="submit" color="primary" size="large">
                            提交
                        </Button>
                    }
                >
                    <Form.Item name="mobile" label="手机号" inputMode="decimal" type="tel"
                               rules={[{ required: true, message: '手机号错误' }]}>
                        <Input placeholder="请输入手机号" />
                    </Form.Item>
                    <Form.Item name="name" label="姓名" rules={[{ required: true, message: '姓名不能为空' }]}>
                        <Input placeholder="请输入姓名" />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
})
