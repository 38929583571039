/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { useCallback, useEffect } from 'react'
import api from '../../api'
import { Toast } from "antd-mobile"
import helper from "../../utils/helper"

function Index(props) {
    console.log(props)
    const checkPermission = useCallback(async () => {
        const tip = Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
        })
        const params = helper.parseParams()
        let page = ''
        if (params.page) {
            page = `?page=${params.page}`
        }
        // console.log(`https://${window.location.host}/redirect${page}` )
        const res = await api.getAuthorizeUrl({ redirect_uri: `https://${window.location.host}/redirect${page}` })
        if (res?.data?.url) {
            window.location.href = res.data.url
        }
        tip.close()
    }, [])

    useEffect(() => {
        checkPermission()
        return () => {
        }
    }, [])
    return <div></div>
}

export default Index
