/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2022/8/8
 */
import { Card, Toast, ProgressBar } from 'antd-mobile'
import styles from './index.module.less'
import { useCallback, useEffect, useState } from "react"
import helper from '@/utils/helper'
import api from "@/api"

function Index(props) {
    const { currentUser } = props
    const [list, setList] = useState([])
    const loadData = useCallback(async (flag = '') => {
        let params = {}
        let tip = Toast.show({
            icon: 'loading',
            content: 'loading',
        })
        const res = await api.getLevelTasksStatesByUserID(helper.getCurrentUserID(), params)
        if (res.data) {
            // res.data[0].finish_count = 3
            // res.data[0].total_count = 7
            setList(res.data)
        }
        tip.close()
    }, [])
    useEffect(() => {
        loadData().then()
        return () => {
        }
    }, [currentUser])

    return <div className={styles.progress}>
        {list.map((item, idx) => {
            let percent = 0
            if (item.total_count > 0) {
                percent = Math.ceil(item.finish_count / item.total_count * 100)
            }
            return <Card title={`${item.book_name}-${item.level_name}`} key={idx} className={styles.card}>
                <div className={styles.item}>
                    <div className={styles.name}>
                        {item.record_name}&nbsp;
                        <span className={styles.tip}>{item.finish_count}/{item.total_count}</span>
                    </div>
                    <ProgressBar percent={percent} />
                </div>
            </Card>
        })}
    </div>
}

export default Index
